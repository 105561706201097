import { ILoginResponse } from 'types/user';
import { checkForMobileDevice, getCookieValue } from 'utils/browserUtils';

const COOKIE_EXPIRY = 60 * 60 * 24 * 365 * 100; // 100 years

/**
 * get Cookies set by subdomain
 * @param name
 * @returns
 */

/**
 * will redirect to desktop.khatabook.com if cookies is already set
 */

export const checkIfCookiePresentAndLogin = () => {
  const kbToken = getCookieValue('kb-token');
  const kbIsNewUser = getCookieValue('kb-is-new-user');
  const kbUserId = getCookieValue('kb-user-id');
  const kbDeviceId = getCookieValue('kb-device-id');
  const kbLanguage = getCookieValue('kb-language');
  const kbPhone = getCookieValue('kb-phone');
  const kbCountryCode = getCookieValue('kb-country-code');
  const kbLogout = getCookieValue('kb-logout');

  if (kbLogout === 'YES') {
    removeAllCookies();
    return false;
  }

  if (!kbToken || !kbUserId || !kbDeviceId) {
    return false;
  } else {
    const isMobileDevice = checkForMobileDevice();
    if (isMobileDevice) {
      window.location.href =
        process.env.NEXT_PUBLIC_BASE_URL === 'https://khatabook.com'
          ? 'https://mobile.khatabook.com'
          : 'http://m.dev-0.khatabook.com';
    } else {
      window.location.href =
        process.env.NEXT_PUBLIC_BASE_URL === 'https://khatabook.com'
          ? 'https://desktop.khatabook.com'
          : 'https://web.dev-0.khatabook.com/';
    }
    return true;
  }
};

/**
 * Set cookies to redirect user to desktop.khatabook.com
 * @param loginRes response after login user
 */
export const setUserCookies = (loginRes: ILoginResponse) => {
  setCookie('kb-token', loginRes.device.token);
  setCookie('kb-is-new-user', loginRes.user.is_new_user);
  setCookie('kb-user-id', loginRes.user.user_id);
  setCookie('kb-language', loginRes.language);
  setCookie('kb-phone', loginRes.user.phone);
  setCookie('kb-country-code', loginRes.user.country_code);
  setCookie('kb-device-id', loginRes.device.device_id);
};

const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    secure: true,
    domain: '.khatabook.com',
    SameSite: 'Lax',
    'max-age': COOKIE_EXPIRY,
    // httpOnly: true,
    ...options
  };

  const env =
    process.env.NEXT_PUBLIC_REACT_APP_NODE_ENV ||
    process.env.REACT_APP_NODE_ENV;

  let updatedCookie =
    encodeURIComponent(env === 'production' ? name : 'dev-' + name) +
    '=' +
    encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  // console.log('updatedCookies' + updatedCookie);

  document.cookie = updatedCookie;
};

export const removeAllCookies = () => {
  /**
  We split the cookies by ; to create an array.
  We iterate through the array of cookies to replace its content
  with an expiry date of today (new Date().toUTCString())
  Having today’s time and date essentially voids the cookie
  and it is removed from the browser.
  */
  document.cookie.split(';').forEach(c => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(
        /=.*/,
        '=;max-age=0;' + 'expires=' + new Date().toUTCString() + ';path=/'
      );
  });
};

export const isIOSDevice = () => {
  if (typeof window != 'undefined') {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(window.navigator.platform) ||
      // iPad on iOS 13 detection
      (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
};
