import Glide from '@glidejs/glide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@mui/material';
import { svgIcons } from 'assets/svg-icons';
import theme from 'features/core/theme';
import { ICarousel } from 'interfaces/core/carousel';
import React, { useEffect, useState } from 'react';
import { GlideCarousel } from './Carousel';

export const Carousel: React.FC<ICarousel> = ({
  options,
  children,
  staticSliders,
  infiniteScroll,
  onSlideVisible,
  hasDots = true,
  hasArrows = true,
  element = 'glide',
  iconLeft = <ArrowBackIcon />,
  iconRight = <ArrowForwardIcon />
}) => {
  const [focusedIndex, setFocusedIndex] = useState(
    options?.direction === 'rtl'
      ? React.Children.count(children) - options?.perView
      : 0
  );
  const [slider, setSlider] = useState(
    staticSliders
      ? new Glide(`.${element}`, { ...options, startAt: focusedIndex })
      : null
  );
  const isLg = useMediaQuery(theme.breakpoints.up('lg')) ? 'lg' : '';

  useEffect(() => {
    if (!slider)
      setSlider(
        new Glide(`.${element}`, { ...options, startAt: focusedIndex })
      );
    if (slider) {
      slider.mount();
      slider.go(`=${focusedIndex}`);

      slider.on('run', () => {
        setFocusedIndex(slider?.index);
        if (onSlideVisible) onSlideVisible(slider?.index);
      });
    }

    return () => {
      try {
        slider?.destroy();
        setSlider(
          new Glide(`.${element}`, {
            ...options,
            startAt: focusedIndex
          })
        );
      } catch (e) {
        console.log(e);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);
  const leftMargin = isLg ? '2rem' : '.25rem';
  const paddingRight = isLg ? '2rem' : '4.75rem';
  return (
    <GlideCarousel
      style={{ paddingLeft: leftMargin, paddingRight: paddingRight }}
      className={element}
    >
      {hasArrows && (
        <div className="glide__arrows" data-glide-el="controls">
          <button
            style={{
              opacity: focusedIndex === 0 ? 0.5 : 1,
              left: !isLg ? '1rem' : '-3.1825rem'
            }}
            disabled={focusedIndex === 0}
            className="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          >
            {svgIcons.leftArrow || 'prev'}
          </button>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: '94%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {React.Children?.map(children, (child, index) => {
              return (
                <li key={index} className="glide__slide">
                  {child}
                </li>
              );
            })}
          </ul>
        </div>
        {hasDots && (
          <div className="glide__bullets" data-glide-el="controls">
            {React.Children.map(children, (bullet: any, index: number) => {
              return (
                <button
                  key={bullet}
                  className={`glide__bullet ${
                    focusedIndex === index ? 'glide__bullet--active' : ''
                  }`}
                  data-glide-dir={`=${index}`}
                >
                  <div
                    className={`bullet ${
                      focusedIndex === index ? 'bullet--active' : ''
                    }`}
                  ></div>
                </button>
              );
            })}
          </div>
        )}
      </div>
      {hasArrows && (
        <div className="glide__arrows" data-glide-el="controls">
          <button
            style={{ right: !isLg ? '3.5rem' : '-2rem' }}
            className="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          >
            {svgIcons.rightArrow || 'Next'}
          </button>
        </div>
      )}
    </GlideCarousel>
  );
};
