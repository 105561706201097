import styled from '@emotion/styled';
export const NavbarWrapper = styled('div')(({ theme }: any) => ({
  zIndex: '100',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: `${theme.palette.commonBgColor.lightPink}`,
  padding: '1rem 6rem',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 1rem'
  },
  color: `${theme.palette.textColors.darkBlack}`,
  '.left-options': {
    display: 'flex',
    '&__name': {
      display: 'flex',
      padding: '0.5rem',
      path: {
        fill: `${theme.palette.commonBgColor.brightRed}`
      }
    },
    '&__pricing': {
      padding: '0.5rem',
      alignSelf: 'center',
      marginLeft: '3rem',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
      '&__pricing': { padding: '0.5rem 2rem' }
    }
  },
  '.right-options': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&__link': {
      marginRight: '24px',
      '&__text': {
        fontWeight: '500'
      }
    },
    '&__phone': {
      backgroundColor: `${theme.palette.commonBgColor.light2Pink}`,
      padding: '0.6rem 1rem',
      borderRadius: '0.5rem',
      display: 'flex',
      '&__icon': {
        display: 'flex',
        alignItems: 'center',
        svg: {
          height: '1rem',
          path: {
            fill: `${theme.palette.commonBgColor.brightPink}`
          }
        }
      },
      '&__number': {
        paddingLeft: '0.50rem'
      },
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '&__business_loan_btn': {
      marginLeft: '1rem',
      border: '1px solid #DC343B',
      padding: '0.45rem 1rem',
      borderRadius: '0.5rem',
      display: 'flex',
      position: 'relative',
      background: '#FFFFFF',
      color: '#DC343B',
      fontWeight: 600,
      fontSize: '1rem',
      cursor: 'pointer',
      '> .tag': {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: '-8px',
        right: '-4px',
        backgroundColor: '#D92D20',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 600,
        borderRadius: '6.25rem',
        height: '0.875rem',
        padding: '8px 6px 8px 6px'
      },
      [theme.breakpoints.down('md')]: {
        width: '11rem'
      }
    },
    '&__logIn_Btn': {
      color: `${theme.palette.textColors.darkBlack}`,
      border: '0',
      backgroundColor: `${theme.palette.commonBgColor.white}`,
      padding: '0.35rem 1rem',
      marginLeft: '12px',
      ':hover': {
        border: '0',
        backgroundColor: `${theme.palette.commonBgColor.darkRed}`,
        color: 'white'
      },
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }
}));
