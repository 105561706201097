import { styled } from '@mui/material';
import { StyledContainerProps } from 'types/styles';

const RootContainerWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'overrideClasses' && prop !== 'styleProp'
})<{ overrideClasses?: string; styleProp?: any }>(
  ({ theme, overrideClasses, styleProp }: StyledContainerProps) => `
  ${overrideClasses};
  color: ${theme?.palette.divider};
  hr {
    border-color: ${theme?.palette.sidebarPrimary.background};
  }
  width: 100%;
`
);

export default RootContainerWrapper;
