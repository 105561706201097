import { svgIcons } from 'assets/svg-icons';

export const BIZ_APP_DOWNLOAD_LINK_LIST = [
  {
    image: svgIcons.playStore,
    link: 'https://ba.sng.link/D71ns/i8tl'
  },
  {
    image: svgIcons.appStore,
    link: 'https://ba.sng.link/D71ns/i8tl'
  }
];

export const BIZ_UPSELL_FEATURES = [
  {
    detail: 'Collect money faster',
    icon: svgIcons.fastMoney
  },
  {
    detail: 'Manage sales on-the-go',
    icon: svgIcons.manageSales
  },
  {
    detail: '100% Data security',
    icon: svgIcons.dataSecurity
  }
];
