import demoAccountAnalytics from './demo-account-analytics';

const useLiveDemoInfo = () => {
  const { exploreDemoAccount } = demoAccountAnalytics();
  const openDemoAccount = () => {
    exploreDemoAccount('onboarding_page');
    navigateToDemoAccount();
  };
  return { openDemoAccount };
};
const navigateToDemoAccount = () => {
  location.href = process.env.NEXT_PUBLIC_DEMO_ACCOUNT_URL;
};

export default useLiveDemoInfo;
