import { useState } from 'react';
import useLandingEvents from '../analytics';
export const useNavbarActions = () => {
  const [loginModal, setLoginModal] = useState<boolean>(false);

  const { homeLoginClick } = useLandingEvents();

  const handleLoginModal = ({ open }: { open: boolean }) => {
    setLoginModal(open);
    open && homeLoginClick();
  };

  return {
    loginModal,
    handleLoginModal
  };
};
