import { sendEvent } from '../../../../analytics_setup';
import { EVENTS } from '../../../../analytics_setup/constants';

const demoAccountAnalytics = () => {
  const exploreDemoAccount = (source: string) => {
    const properties = {
      kb_device: 'web',
      source: source,
      logged_in: false,
      user_id: null
    };
    sendEvent(EVENTS.ExploreDemoAccountClick, properties);
  };

  return { exploreDemoAccount };
};

export default demoAccountAnalytics;
