import styled from '@emotion/styled';

const LoginDiv = styled('div')`
  padding: 0.5rem;
  width: 100%;
  .login-header {
    text-align: end;
  }
  .khatabook-icon-div {
    padding: 1rem;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
  }
  form {
    margin: 1rem 0;
    .text-field-div:first-of-type {
      margin-bottom: 1rem;
    }
    .resend-otp,
    .edit-btn {
      margin: 0;
      padding: 0;
      background: none;
      font-weight: 600;
      :hover {
        background: none;
        color: ${({ theme }: any) => theme.palette.error.dark};
      }
    }
    .resend-otp {
      margin-top: 0.5rem;
    }
    .get-otp,
    .continue {
      width: 100%;
      font-size: 1rem;
      padding: 0.75rem 1.25rem;
      margin-top: 1rem;
    }
    .request-otp-text {
      text-align: center;
      font-weignt: 400;
      color: ${({ theme }: any) => theme.palette.common.black};
      font-size: 1rem;
      margin: 1rem 0;
    }
  }

  .terms-text {
    text-align: center;
    width: 75%;
    color: ${({ theme }: any) => theme.palette.textColors.grey};
    font-size: 0.9rem;
    line-height: 1.25rem;
    margin: 1rem 0 1.5rem;
    &-box {
      display: flex;
      justify-content: center;
    }
    &__link {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
      color: ${({ theme }: any) => theme.palette.textColors.grey};
    }
  }
`;
export default LoginDiv;
