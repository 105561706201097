import React from 'react';
import Typography from '@mui/material/Typography';

import { FEATURE_OBJECT, GROWING_BUSINESS } from '../constant';
import {
  FeatureTitle,
  FeatureCards,
  FeatureCard,
  GrowingBusinessWrapper
} from './growing-business.styled';
import { svgIcons } from 'assets/svg-icons';
// import { useMediaQuery } from '@mui/material';
// import theme from 'features/core/theme';
import { checkForMobileDevice } from 'utils/browserUtils';
import { ControlWrapper } from 'atoms/control-wrapper';

const GrowingBusiness = () => {
  const isMobile = checkForMobileDevice(); // useMediaQuery(theme.breakpoints.down('md'));
  return (
    <GrowingBusinessWrapper>
      <div className="left-asset">{svgIcons.leftBuilding}</div>
      <div className="right-asset">{svgIcons.rightBuilding}</div>
      <FeatureTitle>
        <Typography
          className="title1"
          variant="body2"
          sx={{ fontWeight: '700' }}
        >
          {isMobile
            ? GROWING_BUSINESS.MOBILE_HEADER
            : GROWING_BUSINESS.HEADER.toUpperCase()}
        </Typography>
        <Typography className="title2">
          {GROWING_BUSINESS.SUB_HEADER}
        </Typography>
      </FeatureTitle>
      <FeatureCards>
        {FEATURE_OBJECT.map((feature, index) => (
          <ControlWrapper
            shouldShow={
              isMobile
                ? feature.shouldShowInMobile
                : feature.shouldShowInDesktop
            }
            key={index}
          >
            <FeatureCard isMobile={isMobile} key={index} index={index}>
              <div className="feature_details">
                <div className="feature_details__header">
                  {/* {isMobile && <div className="icon">{feature.ICON}</div>} */}
                  <Typography
                    className="feature_details__header__title"
                    variant="h3"
                  >
                    {feature.TITLE}
                  </Typography>
                </div>
                <Typography
                  className="feature_details__description"
                  variant="body1"
                >
                  {feature.DESCRIPTION}
                </Typography>
                {feature.LIST.map((element, i) => {
                  if (!isMobile && index === 0 && i === 2) return null;
                  return (
                    <div className="feature_list" key={i}>
                      <div>{svgIcons.tick}</div>
                      <Typography className="feature_list_item" variant="body1">
                        {element}
                      </Typography>
                    </div>
                  );
                })}
              </div>
              <div className="featureImage">{feature.IMAGE}</div>
            </FeatureCard>
          </ControlWrapper>
        ))}
      </FeatureCards>
    </GrowingBusinessWrapper>
  );
};

export default GrowingBusiness;
