// import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Login from 'features/onboarding';
import { svgIcons } from 'assets/svg-icons';
import Button from 'atoms/button';
import { NavbarWrapper } from './navbar.styled';
import { useNavbarActions } from './actions';
import { checkForMobileDevice } from 'utils/browserUtils';
import { ControlWrapper } from 'atoms/control-wrapper';

const Navbar = () => {
  const { loginModal, handleLoginModal } = useNavbarActions();
  // const navigate = useNavigate()
  const onClickHome = () => {
    // navigate('/')
  };

  const onClickPricing = () => {
    // navigate('/pricing')
  };

  const onBusinessLoanClick = () => {
    const isMobileDevice = checkForMobileDevice();
    const platform = isMobileDevice ? 'mobile' : 'desktop';
    const url = `https://khatabook.com/loans?utm_source=KBwebsite&utm_medium=${platform}&utm_campaign=TopButton`;
    window.open(url);
  };

  return (
    <>
      {loginModal && <Login handleLoginModal={handleLoginModal} />}
      <NavbarWrapper>
        <div className="left-options">
          <div className="left-options__name" onClick={onClickHome}>
            {svgIcons.khatabook_main}
          </div>
          {/* <div className="left-options__pricing">
            <Typography onClick={onClickPricing} variant="body1">
              Pricing
            </Typography>
          </div>*/}
        </div>
        <div className="right-options">
          <div className="right-options__phone">
            <div className="right-options__phone__icon">
              {svgIcons.phoneFilled}
            </div>
            <Typography
              variant="body1"
              className="right-options__phone__number"
            >
              9606800800
            </Typography>
          </div>
          <ControlWrapper shouldShow={false}>
            <div
              className="right-options__business_loan_btn"
              onClick={onBusinessLoanClick}
            >
              <div className="right-options__phone__number">Business Loans</div>
              <div className="tag">New</div>
            </div>
          </ControlWrapper>
          <Button
            onClick={() => {
              handleLoginModal?.({ open: true });
            }}
            className="right-options__logIn_Btn"
            variant="outlined"
          >
            Log In
          </Button>
        </div>
      </NavbarWrapper>
    </>
  );
};

export default Navbar;
