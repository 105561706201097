export enum LoginState {
  REQUESTING_OTP = 'REQUESTING_OTP',
  OTP_REQUESTED = 'OTP_REQUESTED',
  OTP_REQUEST_FAIED = 'OTP_REQUEST_FAIED',
  SUBMITTING = 'SUBMITTING',
  SUBMIT_FAILED = 'SUBMIT_FAILED',
  SUBMITTED = 'SUBMITTED'
}

export enum OnboardingErrorTypes {
  NETWORK_ERROR = 'NETWORK_ERROR',
  PARAMS_INVALID = `PARAMS_INVALID`,
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
  INVALID_TOKEN = 'INVALID_TOKEN',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export const OnboardingErrors: { [key in OnboardingErrorTypes]: string } = {
  [OnboardingErrorTypes.NETWORK_ERROR]: 'Please check your internet & retry',
  [OnboardingErrorTypes.PARAMS_INVALID]: `"+91xxxxxxxxxx" is an invalid number. Please check & try again.`,
  [OnboardingErrorTypes.RATE_LIMIT_EXCEEDED]:
    'Max. attempts exceeded for this action. Please wait then retry.',
  [OnboardingErrorTypes.INVALID_TOKEN]:
    'Invalid OTP entered. Please check & try again.',
  [OnboardingErrorTypes.UNKNOWN_ERROR]:
    'Error detected. Please contact customer support.'
};
