import React from 'react';
import { Divider as DividerComponent } from '@mui/material';
import RootContainerWrapper from './style';
import { StyledContainerProps } from 'types/styles';

const Divider = ({
  className,
  overrideClasses,
  styleProp
}: StyledContainerProps) => {
  return (
    <RootContainerWrapper
      overrideClasses={overrideClasses}
      styleProp={styleProp}
    >
      <DividerComponent className={className} />
    </RootContainerWrapper>
  );
};

export default React.memo(Divider);
