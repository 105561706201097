import { useState, useEffect } from 'react';

// interval = seconds to tick tok the clock(defaulted to 1sec)
// timeout = number of seconds to be timed
export function useTimer(timeout: number, interval = 1) {
  const [timer, setTimer] = useState<any>(null);
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    if (secondsLeft < 1) {
      clearTimer();
    }
  }, [secondsLeft]);

  function startTimer() {
    // clear if any
    clearTimer();

    // start timer
    setSecondsLeft(timeout);
    setTimer(
      setInterval(() => {
        setSecondsLeft((secondsLeft: number) => secondsLeft - interval);
      }, interval * 1000)
    );
  }
  function clearTimer() {
    clearInterval(timer);
    setTimer(null);
    setSecondsLeft(0);
  }

  return { secondsLeft, startTimer, clearTimer };
}
