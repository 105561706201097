// import { isUserLoggedIn } from 'src/integrations/user'
import { ControlWrapper } from 'atoms/control-wrapper';
import { useLandingActions } from './actions';
import Footer from './footer';
import MainContent from './main-content';
import Navbar from './navbar';
import { LandingPageWrapper } from './styled';

import Login from '../onboarding';
import Banner from './navbar/banner/banner';
const LandingPage = ({ showLoginPopup }: { showLoginPopup: boolean }) => {
  const {
    phoneNumber,
    setPhoneNumber,
    loginModal,
    handleLoginModal,
    updateSourceToLoginPopup,
    source
  } = useLandingActions(showLoginPopup);
  const isLoggedIn = false;

  return (
    <LandingPageWrapper>
      <Banner onClickStartNow={() => handleLoginModal({ open: true })} />
      <ControlWrapper shouldShow={!isLoggedIn}>
        <Navbar />
        <MainContent
          setPhoneNumber={setPhoneNumber}
          handleLoginModal={handleLoginModal}
          updateSourceToLoginPopup={updateSourceToLoginPopup}
        />
        <Footer />
      </ControlWrapper>
      {loginModal && (
        <Login
          source={source}
          phoneNumber={phoneNumber}
          handleLoginModal={handleLoginModal}
        />
      )}
    </LandingPageWrapper>
  );
};
export default LandingPage;
