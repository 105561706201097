import styled from '@emotion/styled';

const BannerStyle = styled('div')(({ theme }: any) => ({
  padding: '12px',
  background: '#14243D',
  justifyContent: 'space-between',
  display: 'flex',
  '.title': {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '1.2'
  },
  '.sub-title': {
    fontSize: '16px',
    lineHeight: '1.2'
  },
  '.cta': {
    background: 'white',
    marginRight: '5rem',
    padding: '8px',
    lineHeight: '1.1',
    borderRadius: '6px',
    alignSelf: 'center'
  },
  '.img-container': {
    display: 'flex',
    flex: 3,
    marginLeft: '5rem'
  },
  '.details-container': {
    color: '#FFFFFF',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: '18px',
    marginRight: '18px',
    flexDirection: 'column'
  }
}));

export default BannerStyle;
