import { styled } from '@mui/material/styles';

export const GlideCarousel = styled(`div`)(({ theme }) => ({
  display: 'flex',
  '& .glide__arrow': {
    height: '90%',
    margin: 0,
    padding: 0,
    border: 'none',
    color: theme.palette.grey.A400,
    backgroundColor: 'transparent',
    '& > *': { cursor: 'pointer' }
  },
  '& .glide__bullets': {
    [theme.breakpoints.down('lg')]: { marginLeft: '1.5rem' }
  },
  '.bullet': {
    borderRadius: '50%',
    marginLeft: '4px',
    backgroundColor: '#D9D9D9',
    width: '10px',
    height: '10px'
  },
  '& .bullet--active': {
    backgroundColor: theme.palette.error.main
  },
  '& .glide__bullet': {
    marginTop: '1.875rem',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& path:first-of-type': { opacity: '0.2' },
    '& path:nth-of-type(2)': { opacity: '0.4', fill: theme.palette.grey[500] }
  },
  '& .glide__bullet--active': {
    '& path:first-of-type': {
      opacity: '1',
      fill: theme.palette.error.main
    }
  }
}));
