import { setUserInAnalytics } from 'analytics_setup';
import { setFetchHeaders } from 'api';
import { useTimer } from 'constants/hooks';
import { INDIA_COUNTRY_CODE } from 'constants/phone';
import { setUserCookies } from 'features/landing/helper';
import { dologinAPICall, requestOTPAPICall } from 'network/auth';
import { defaultHeaders } from 'network/headers';
import React, { useEffect, useState } from 'react';
import { ILogin, IOtp } from 'types/auth';
import { ILoginResponse } from 'types/user';
import useOnboardingEvents from './analytics';
import { LoginState, OnboardingErrors } from './utils/constants';
import { checkForMobileDevice } from 'utils/browserUtils';
import useLandingEvents from 'features/landing/analytics';

interface OnboardingActionsProps {
  phoneNumber?: string;
  source?: string;
}

function getErrorMessage(e) {
  let error: string;
  if (e?.error) {
    error = OnboardingErrors[e.error];
  } else if (!navigator.onLine) {
    error = OnboardingErrors.NETWORK_ERROR;
  } else {
    error = OnboardingErrors.UNKNOWN_ERROR;
  }

  return error;
}

const REREQUEST_OTP_TIMEOUT = 60;
const useOnboardingActions = ({
  phoneNumber = '',
  source = ''
}: OnboardingActionsProps) => {
  const {
    requestOtpEvent,
    otpSendEvent,
    successLoginEvent,
    resendOtpEvent,
    KYUPopupRenderEvent
  } = useOnboardingEvents();
  const [open, setOpen] = useState(true);
  const [isOpenBusinessDailog, setIsOpenBusinessDailog] = useState(false);
  const [phone, setPhone] = useState<string>(phoneNumber);
  const [otp, setOtp] = useState<string>();
  const [submitState, setSubmitState] = useState<LoginState | null>(null);
  const { secondsLeft, startTimer } = useTimer(REREQUEST_OTP_TIMEOUT);
  const [error, setError] = useState<null | string>(null);
  const { homeStartTrialClick } = useLandingEvents();

  useEffect(() => {
    if (submitState === LoginState.REQUESTING_OTP) {
      startTimer();
    }
    if (submitState === LoginState.SUBMITTED) {
      handleClose();

      handleOpenBusinessDialog();
    }
  }, [submitState, handleOpenBusinessDialog, startTimer]);

  useEffect(() => {
    if (source == 'getStarted' && phone.length == 10) {
      onSubmit();
    }
  }, []);

  function handleOpenBusinessDialog() {
    setIsOpenBusinessDailog(true);
    KYUPopupRenderEvent({
      flow: 'onboarding'
    });
  }

  function handleClose() {
    setError(null);
    setOpen(false);
  }
  function handleEdit() {
    setError(null);
    setSubmitState(null);
    setOtp('');
  }
  function handleChange(e: React.BaseSyntheticEvent) {
    setError(null);
    if (e.target.name === 'phone') {
      const nonNumReg = /[^0-9]/g;
      e.target.value = e.target.value.replace(nonNumReg, '');
      setPhone(e.target.value);
    }
    if (e.target.name === 'otp') {
      setOtp(e.target.value);
    }
  }

  async function handleSendOtp(type = 'initialSendOtp') {
    if (!phone || !phone.length) {
      if (type === 'resend') {
        resendOtpEvent(false);
      }
      return requestOtpEvent(false);
    }
    if (!phone?.length) return;
    setOtp('');
    setError(null);
    setSubmitState(LoginState.REQUESTING_OTP);
    try {
      if (type === 'resend') {
        resendOtpEvent(true);
      }
      requestOtpEvent(true);
      await requestOTP(phone);
      setSubmitState(LoginState.OTP_REQUESTED);
      otpSendEvent(true);
    } catch (e) {
      setError(getErrorMessage(e));
      setSubmitState(null);
    }
  }

  async function onSubmit() {
    if (!submitState) {
      handleSendOtp();
    } else if (otp?.length && phone?.length) {
      setSubmitState(LoginState.SUBMITTING);
      try {
        const res = await authenticate(phone, otp);
        if (res.error) {
          throw Error('Error occurred during login');
        }
        successLoginEvent({ mode: 'Khatabook OTP' });
        //write start trial event here
        homeStartTrialClick({
          TrialPeriod: 7,
          Phone: Boolean(phone.length)
        });
        setSubmitState(LoginState.SUBMITTED);

        const isMobileDevice = checkForMobileDevice();
        if (
          window.location.hostname === 'localhost' &&
          !res.error &&
          isMobileDevice
        ) {
          const logRes = res as ILoginResponse;
          const token = logRes?.device?.token,
            device_id = logRes?.device?.device_id,
            user_phone = logRes?.user?.phone,
            user_id = logRes?.user?.user_id,
            is_new_user = logRes?.user?.is_new_user;

          const params = new URLSearchParams();
          params.append('token', token);
          params.append('device_id', device_id);
          params.append('phone', user_phone);
          params.append('user_id', user_id);
          params.append('is_new_user', is_new_user.toString());

          window.location.href = `http://localhost:3030/?${params.toString()}`;
          return;
        }
        if (isMobileDevice) {
          window.location.href =
            process.env.NEXT_PUBLIC_BASE_URL === 'https://khatabook.com'
              ? `https://mobile.khatabook.com${window.location.search}`
              : `http://m.dev-0.khatabook.com${window.location.search}`;
        } else {
          window.location.href =
            process.env.NEXT_PUBLIC_BASE_URL === 'https://khatabook.com'
              ? `https://desktop.khatabook.com${window.location.search}`
              : `https://web.dev-0.khatabook.com${window.location.search}`;
        }
      } catch (e: any) {
        setError(getErrorMessage(e));
        setSubmitState(LoginState.OTP_REQUESTED);
      }
    }
  }
  async function requestOTP(phone: string) {
    if (!phone?.length) return;

    const initialOtpState: IOtp = {
      country_code: INDIA_COUNTRY_CODE,
      phone: phone,
      app_signature: 'Jc/Zu7qNqQ2' || null //TODO: update this to env variable
    };
    setFetchHeaders({
      ...defaultHeaders
    });
    return await requestOTPAPICall(initialOtpState);
  }

  const authenticate = async (
    phone: string,
    otp: string
  ): Promise<{ error?: string; isNewUser?: boolean } | ILoginResponse> => {
    const loginState: ILogin = {
      country_code: INDIA_COUNTRY_CODE,
      phone: phone,
      khatabook_otp: otp,
      device_model: 'browser'
    };

    setFetchHeaders({
      ...defaultHeaders
    });
    const loginRes: ILoginResponse = await dologinAPICall(loginState);
    if (!loginRes || !loginRes.user || !loginRes.device) {
      throw Error(`error with login res : ${JSON.stringify(loginRes)}`);
    }
    const { user } = loginRes;
    /**
     * Setting user cookes which will be used to automatically re-login to user of cookies available
     */
    setUserCookies(loginRes);
    setUserInAnalytics(user.user_id);
    return window.location.hostname === 'localhost'
      ? loginRes
      : { isNewUser: loginRes.user.is_new_user };
  };

  return {
    open,
    phone,
    otp,
    submitState,
    secondsLeft,
    isOpenBusinessDailog,
    handleClose,
    handleChange,
    handleEdit,
    onSubmit,
    handleSendOtp,
    handleOpenBusinessDialog,
    error
  };
};

export default useOnboardingActions;
