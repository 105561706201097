import { sendEvent } from 'analytics_setup';
import { EVENTS } from 'analytics_setup/constants';

const useLandingEvents = () => {
  let { pathname = '' } = typeof window === 'undefined' ? {} : window.location;
  pathname = pathname.replace('/', '');
  const pageName =
    pathname === ''
      ? 'Landing Page'
      : pathname.charAt(0).toUpperCase() + pathname.slice(1) + ' Page';

  const homeLoginClick = (data?: object) => {
    sendEvent(EVENTS.HomeLoginClick, {
      ...data,
      Feature: pageName,
      Screen: 'login'
    });
  };

  const homeStartTrialClick = (data?: object) => {
    sendEvent(EVENTS.HomeStartTrialClick, {
      ...data,
      Feature: pageName,
      Screen: 'login'
    });
  };
  return {
    homeLoginClick,
    homeStartTrialClick
  };
};

export default useLandingEvents;
