import { Height } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const HowWeHelpWrapper = styled('div')(({ theme }: any) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    '.title1': {
      textAlign: 'center'
    },
    '.caption': {
      fontWeight: '400',
      color: '#556F89',
      textAlign: 'center',
      fontSize: '1rem'
    },
    '.mobileGridContainer': {
      margin: '2rem 0rem 4rem 0rem',
      backgroundColor: '#F6F7F9',
      border: '1px solid #E1E6EF',
      borderRadius: '1rem',
      padding: '0rem 1rem 1rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.img': {
        width: '100%'
      },
      '.title1': {
        marginTop: '2rem',
        fontSize: '1.25rem',
        fontWeight: '700'
      },
      '.caption': {
        marginTop: '1rem',
        fontSize: '0.875rem'
      },
      '.exloreKB': {
        marginTop: '2rem',
        width: 'fit-content',
        alignSelf: 'center',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '28px',
        borderRadius: '8px',
        marginBottom: '2rem',
        padding: '.75rem 1.5rem',
        ':hover': {
          backgroundColor: `${theme.palette.commonBgColor.darkRed}`,
          color: 'white'
        },
        backgroundColor: `${theme.palette.commonBgColor.brightRed}`
      }
    }
  }
}));
