import BannerStyle from './style';
import useBannerActions from './actions';
import { Button } from '@mui/material';
import MobileMarketingBanner from './mobile-marketing-banner'; // import theme from 'features/core/theme';
// import { svgIcons } from 'assets/svg-icons';
// import { BannerItem } from './model';
import { checkForMobileDevice } from 'utils/browserUtils';

const Banner = ({ onClickStartNow }: { onClickStartNow: () => void }) => {
  const { bannerData, showBanner } = useBannerActions();
  /**
   * Its checking that if device is Mobile or not.
   */
  const isMobile = checkForMobileDevice(); // useMediaQuery(theme.breakpoints.down('sm'));
  const background = bannerData?.configs?.background_color ?? '#FFFFFF';
  return bannerData !== undefined && showBanner ? (
    isMobile ? (
      <MobileMarketingBanner bannerData={bannerData} />
    ) : (
      <BannerStyle style={{ backgroundColor: background }}>
        <div className="img-container">
          <img
            src={bannerData.images[0].image_url}
            width={bannerData.images[0].configs.width + 'px'}
            height={bannerData.images[0].configs.height + 'px'}
          />
          <div className="details-container">
            <div
              style={{
                fontSize:
                  bannerData.fields[0].configs?.text_configs?.font_size + 'px',
                fontWeight:
                  bannerData.fields[0].configs.text_configs.font_weight,
                color: bannerData.fields[0].configs.text_configs.font_color
              }}
              className="title"
            >
              {bannerData.fields[0].text}
            </div>
            <div
              style={{
                fontSize:
                  bannerData.fields[1].configs.text_configs.font_size + 'px',
                fontWeight:
                  bannerData.fields[1].configs.text_configs.font_weight,
                color: bannerData.fields[1].configs.text_configs.font_color
              }}
              className="sub-title"
            >
              {bannerData.fields[1].text}
            </div>
          </div>
        </div>
        <Button
          onClick={() => onClickStartNow()}
          className="cta"
          style={{
            backgroundColor: bannerData.cta.configs.background_color,
            height: bannerData.cta.configs.height + 'px',
            width: bannerData.cta.configs.width + 'px',
            fontSize: bannerData.cta.configs.text_configs.font_size,
            fontWeight: bannerData.cta.configs.text_configs.font_weight,
            color: bannerData.cta.configs.text_configs.font_color
          }}
        >
          {bannerData.cta.text}
        </Button>
      </BannerStyle>
    )
  ) : null;
};

export default Banner;
