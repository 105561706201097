import { useState } from 'react';

import { checkIfCookiePresentAndLogin } from './helper';

export const useLandingActions = (isShowLoginModal = false) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [loginModal, setLoginModal] = useState<boolean>(isShowLoginModal);
  const [source, setSource] = useState<string>('');

  checkIfCookiePresentAndLogin();

  const updateSourceToLoginPopup = source => {
    setSource(source);
  };
  const handleLoginModal = ({
    open,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    phoneNumber
  }: {
    open: boolean;
    phoneNumber?: string;
  }) => {
    setLoginModal(open);
  };

  return {
    phoneNumber,
    setPhoneNumber,
    loginModal,
    source,
    handleLoginModal,
    updateSourceToLoginPopup
  };
};
