import { Link } from '@mui/material';
import { BannerItem } from './model';
import BannerStyle from './style';

const MobileMarketingBanner = ({ bannerData }: { bannerData: BannerItem }) => {
  const background = bannerData?.configs?.background_color ?? '#FFFFFF';
  return (
    <BannerStyle style={{ backgroundColor: background }}>
      <div>
        <div>
          <div
            style={{
              fontSize:
                bannerData.fields[0].configs.mobile_text_configs.font_size +
                'px',
              fontWeight:
                bannerData.fields[0].configs.mobile_text_configs.font_weight,
              color: bannerData.fields[0].configs.mobile_text_configs.font_color
            }}
            className="title"
          >
            {bannerData.fields[0].text}
          </div>
          <div
            style={{
              marginTop: '4px',
              fontSize:
                bannerData.fields[1].configs.mobile_text_configs.font_size +
                'px',
              fontWeight: bannerData.fields[1].configs.text_configs.font_weight,
              color: bannerData.fields[1].configs.text_configs.font_color
            }}
            className="sub-title"
          >
            {bannerData.fields[1].text}
          </div>
          <div
            style={{
              fontStyle: 'italic',
              marginTop: '10px',
              fontSize: '18px',
              color:
                bannerData.fields[1].configs.mobile_text_configs.font_color,
              fontWeight:
                bannerData.fields[1].configs.mobile_text_configs.font_color
            }}
          >
            {'Go to khatabook.com on your computer.'}
          </div>
        </div>
      </div>
    </BannerStyle>
  );
};

export default MobileMarketingBanner;
