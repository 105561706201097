import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { styled } from '@mui/material/styles';

import Button from 'atoms/button';
import Transition from '../transition';
import { DialogProps } from '../ts-utils';

const StyledDialog = styled(MuiDialog)`
  .dialog {
    &__actions {
      margin: 0.5rem;
    }
  }
`;

function Dialog({
  open: propsOpen,
  buttonText,
  title,
  children,
  actionText,
  closeText,
  onAccept,
  onReject,
  defaultOpen,
  hasTransition,
  closeOnAction,
  buttonComponent,
  paperProps,
  classes,
  actionColor
}: DialogProps) {
  const [open, setOpen] = React.useState(defaultOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onReject && onReject();
  };

  const trueValue = propsOpen !== undefined ? propsOpen : open;
  return (
    <div className="dialog-container">
      {buttonComponent ? (
        <div onClick={handleClickOpen}>{buttonComponent}</div>
      ) : buttonText ? (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          className="dialog__open-button"
        >
          {buttonText}
        </Button>
      ) : null}
      <StyledDialog
        open={trueValue}
        TransitionComponent={hasTransition ? Transition : undefined}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialog"
        PaperProps={paperProps}
      >
        {title && (
          <MuiDialogTitle className="dialog__title">{title}</MuiDialogTitle>
        )}
        <MuiDialogContent
          className="dialog__content"
          classes={classes}
          tabIndex={0}
        >
          <MuiDialogContentText
            id="alert-dialog-slide-description"
            className="dialog__content-text"
            component={'div'}
          >
            {children}
          </MuiDialogContentText>
        </MuiDialogContent>
        {actionText || closeText ? (
          <MuiDialogActions className="dialog__actions">
            {closeText ? (
              <Button
                size="medium"
                onClick={handleClose}
                className="dialog__actions-reject"
                pattern="base"
                sx={{
                  boxShadow: '0 0 1px rgb(0 0 0 / 20%)'
                }}
              >
                {closeText}
              </Button>
            ) : null}
            {actionText ? (
              <Button
                size="medium"
                className="dialog__actions-accept"
                color={actionColor || 'primary'}
                variant="contained"
                onClick={() => {
                  if (onAccept) onAccept();
                  if (closeOnAction) handleClose();
                }}
              >
                {actionText}
              </Button>
            ) : null}
          </MuiDialogActions>
        ) : null}
      </StyledDialog>
    </div>
  );
}

Dialog.defaultProps = {
  defaultOpen: false,
  hasTransition: true,
  closeOnAction: true
};
export default Dialog;

interface confirmProps {
  acceptLabel?: React.ReactNode;
  rejectLabel?: React.ReactNode;
  [key: string]: any;
}
export const confirm = (
  children: React.ReactNode,
  options: confirmProps
): Promise<any> => {
  const { acceptLabel, rejectLabel, ...otherOptions } = options;
  const body = document.body;
  if (!body) {
    throw Error('Body is not found in the document.body');
  }
  const node = document.createElement('div');
  body.appendChild(node);
  // const root = ReactDOM.createRoot(node)

  const unmount = () => {
    unmountComponentAtNode(node);
  };

  return new Promise((resolve, reject) => {
    // render the confirmdialog component with appropriate props
    const onAccept = (...args: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolve(...args);
      unmount();
    };

    const onReject = (...args: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reject(...args);
      setTimeout(() => {
        unmount();
      }, 500);
    };
    render(
      <React.StrictMode>
        <Dialog
          {...otherOptions}
          defaultOpen
          actionText={acceptLabel}
          closeText={rejectLabel}
          onAccept={onAccept}
          onReject={onReject}
        >
          {children}
        </Dialog>
      </React.StrictMode>,
      node
    );
  });
};
