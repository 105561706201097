import styled from '@emotion/styled';

export const MobileBannerWrapper = styled('div')(({ theme }: any) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    position: 'relative',
    height: '302px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FDF2F3',
    backgroundImage:
      'url(https://khatabook-assets.s3.ap-south-1.amazonaws.com/common/images/mobile_landing_banner_transparent.svg)',
    marginBottom: '-3rem'
  }
}));
