import { sendEvent } from 'analytics_setup';
import { EVENTS } from 'analytics_setup/constants';
import { KeyValue } from 'types/general';

// const sleep = async () => {
//   await new Promise((resolve) => {
//     setTimeout(resolve, 1000)
//   })
// }

const useOnboardingEvents = () => {
  const requestOtpEvent = (isPhone: boolean) => {
    sendEvent(EVENTS.OnboardingRequestOtp, {
      Phone: isPhone
    });
  };

  const otpSendEvent = (isPhone: boolean) => {
    sendEvent(EVENTS.OnboardingOtpSent, { Phone: isPhone });
  };

  const successLoginEvent = (props: any) => {
    sendEvent(EVENTS.LoginSuccess, { props });
  };

  const resendOtpEvent = (isPhone: boolean) => {
    sendEvent(EVENTS.OnboardingResendOtpClick, {
      Phone: isPhone
    });
  };

  const updateKYUInfoEvent = (data: object) => {
    sendEvent(EVENTS.KYUUpdateSuccess, { ...data });
  };

  const KYUPopupRenderEvent = (data: object) => {
    sendEvent(EVENTS.KYUPermissionPopupShown, { ...data });
  };

  const pageLoadEvent = (data: KeyValue) => {
    sendEvent(EVENTS.OnboardingPageLoad, { ...data });
  };

  return {
    requestOtpEvent,
    otpSendEvent,
    successLoginEvent,
    resendOtpEvent,
    updateKYUInfoEvent,
    KYUPopupRenderEvent,
    pageLoadEvent
  };
};

export default useOnboardingEvents;
