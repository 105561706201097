import axios from 'axios';
import { BANNER_DATA_S3_URL } from 'features/landing/constant';
import { useEffect, useState } from 'react';
import { BannerItem } from './model';

const useBannerActions = () => {
  const [bannerData, setBannerData] = useState<BannerItem>();
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    axios.get(BANNER_DATA_S3_URL).then(
      response => {
        const values = Object.values(response.data);
        const bannerItems: BannerItem[] = Object.values(values[0]);
        if (bannerItems) {
          bannerItems.forEach(bannerItem => {
            if (bannerItem.deleted == false) {
              setBannerData(bannerItem);
              isCurrentTimeInRange(bannerItem.start_time, bannerItem.end_time);
            }
          });
        }
      },
      error => {
        console.log('Not able to fetch banner from network' + error.data);
      }
    );
  }, []);

  const isCurrentTimeInRange = (startTime: number, endTime: number) => {
    const date = new Date();
    const currentTime = date.getTime();
    if (currentTime >= startTime && currentTime <= endTime) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  };

  return {
    bannerData,
    setBannerData,
    showBanner
  };
};

export default useBannerActions;
