import styled from '@emotion/styled';

export const PortfolioWrapper = styled('div')(({ theme }: any) => ({
  margin: '4rem 6.5rem',
  border: `1px solid #B3C7E5`,
  borderRadius: '0.8rem',
  padding: '3rem 0',
  backgroundColor: `${theme.palette.commonBgColor.veryLightYellow}`,
  '.divider-section': {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '0 6rem',
    [theme.breakpoints.down('md')]: {
      padding: 0
    },

    '&__title': {
      minWidth: '12rem',
      color: `#A3AFC2`,
      fontWeight: '600'
    }
  },
  '.portfolio': {
    '&__banner': {
      marginTop: '1.5rem',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap'
    },
    '&__icon': {
      padding: '1rem',
      color: `${theme.palette.textColors.grey}`
    },
    '&__title': {
      fontWeight: '600',
      fontSize: 20
    },
    '&__description': {
      width: '80%',
      alignSelf: 'center',
      padding: '0.5rem',
      color: `${theme.palette.textColors.grey}`
    }
  },

  [theme.breakpoints.down('md')]: {
    margin: '0',
    padding: '2rem 1rem',
    '.divider-section': {
      padding: 0,
      justifyContent: 'center',
      '.divider': {
        backgroundColor: '#F1707F'
      },
      '&__title': {
        color: '#F1707F'
      }
    }
  }
}));

export const PortfolioCard = styled('div')(({ theme, index }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '33%',
  '.icon': {
    marginTop: index == 3 || index == 4 ? '5rem' : '1rem',
    color: `${theme.palette.textColors.grey}`
  },
  [theme.breakpoints.down('lg')]: {
    width: '50%'
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const PortfolioWrapperMobile = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('md')]: {
    padding: '2.5rem 1rem 3.75rem 1rem',
    backgroundColor: '#FDF2F3'
  }
}));
