export const landingPageIcon: any = {
  desktopPageHeaderIcon: (
    <svg
      width="212"
      height="81"
      viewBox="0 0 212 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.0002 70C60.0002 68.3431 61.3434 67 63.0002 67H94.0002C95.6571 67 97.0002 68.3431 97.0002 70V73H60.0002V70Z"
        fill="#4F5E71"
      />
      <path
        d="M53.0002 59C53.0002 57.3431 54.3434 56 56.0002 56H84.0002C85.6571 56 87.0002 57.3431 87.0002 59V72H53.0002V59Z"
        fill="#BAC1C8"
      />
      <path
        d="M83.0002 58C83.0002 56.8954 83.8957 56 85.0002 56V56C86.1048 56 87.0002 56.8954 87.0002 58V72H83.0002V58Z"
        fill="#4F5E71"
      />
      <rect x="42.0002" y="72" width="170" height="9" rx="2" fill="#9CA7B3" />
      <path
        d="M118 70C118 69.4477 118.448 69 119 69H149C149.553 69 150 69.4477 150 70V70.9999C150 71.5522 149.553 71.9999 149 71.9999H119C118.448 71.9999 118 71.5522 118 70.9999V70Z"
        fill="#8F262A"
      />
      <path
        d="M118 69.5C118 69.2239 118.224 69 118.5 69H148.5C148.776 69 149 69.2239 149 69.5V69.5C149 69.7761 148.776 70 148.5 70H118.5C118.224 70 118 69.7761 118 69.5V69.5Z"
        fill="#DC343B"
      />
      <path d="M143.5 54H129L135.5 70H150L143.5 54Z" fill="#8F262A" />
      <path d="M142.5 54H129L135.5 70H149L142.5 54Z" fill="#DC343B" />
      <path d="M142.5 54L127.5 54.5L141 62L147 65.5L142.5 54Z" fill="#8F262A" />
      <path
        d="M102.267 11H169.643C170.242 11 170.706 11.522 170.637 12.1163L165.707 54.2325C165.589 55.2403 164.735 56 163.721 56H99.248C98.0513 56 97.1224 54.9561 97.2616 53.7675L102.267 11Z"
        fill="#8F262A"
      />
      <path d="M102.201 12H169.544L165 49H97.8596L102.201 12Z" fill="#FDF2F3" />
      <path
        d="M115.733 22.8265C115.786 22.3557 116.184 22 116.658 22H160.943C161.504 22 161.938 22.4921 161.867 23.0484L158.928 46.1863C158.869 46.6514 158.473 47 158.005 47H114.042C113.487 47 113.055 46.5165 113.117 45.9644L115.733 22.8265Z"
        fill="#9CA7B3"
      />
      <path
        d="M107.132 17.8205C107.188 17.3524 107.585 17 108.056 17H158.936C159.499 17 159.933 17.4966 159.858 18.0551L156.48 43.193C156.417 43.6551 156.023 44 155.557 44H105.049C104.491 44 104.059 43.5125 104.125 42.9584L107.132 17.8205Z"
        fill="white"
      />
      <path
        d="M107.067 17.8918C107.122 17.3844 107.55 17 108.061 17H158.978C159.595 17 160.065 17.5528 159.965 18.1615L159.5 21H106.729L107.067 17.8918Z"
        fill="#424248"
      />
      <circle cx="108.971" cy="18.9703" r="0.97028" fill="#DD3C54" />
      <circle cx="112.205" cy="18.9703" r="0.97028" fill="#FEBD27" />
      <circle cx="115.439" cy="18.9703" r="0.97028" fill="#3CB17C" />
      <ellipse
        rx="8.65426"
        ry="5.70435"
        transform="matrix(-0.779616 0.626258 0.626258 0.779616 73.3195 9.867)"
        fill="#E6A716"
      />
      <ellipse
        rx="8.65426"
        ry="4.61494"
        transform="matrix(-0.779616 0.626258 0.626258 0.779616 72.7291 9.28819)"
        fill="#FEBD27"
      />
      <ellipse
        rx="6.2481"
        ry="3.03545"
        transform="matrix(-0.779616 0.626258 0.626258 0.779616 72.6348 9.31603)"
        fill="#FFE298"
      />
      <path
        d="M68.0005 56.0002L81.0005 56.0002L81.0005 42.7496C81.0005 39.8503 78.6502 37.5 75.7509 37.5L68.0005 37.5L68.0005 56.0002Z"
        fill="#133866"
      />
      <path
        d="M50.736 37.6659L74.81 37.666C73.8471 37.9382 73.0202 38.3892 72.311 38.9915C71.0927 40.0262 70.2329 41.4981 69.6212 43.2477C68.3995 46.7422 68.1525 51.3907 68.0516 56.0126C68.0338 56.8253 68.0206 57.6371 68.0075 58.4419C67.9458 62.2276 67.8866 65.8599 67.3724 68.708C67.0608 70.4342 66.5849 71.8525 65.8541 72.8366C65.1295 73.8123 64.1515 74.3659 62.8073 74.3659L42.487 74.3659L40.9039 48.1086C40.5625 42.4458 45.0629 37.6659 50.736 37.6659Z"
        fill="white"
        stroke="black"
        strokeWidth="0.3"
      />
      <path
        d="M19.115 49.5159L36.8355 49.5159C55.0164 49.5159 39.367 73.1108 61.115 74.5159C61.115 74.5159 49.3785 74.5159 34.2469 74.5159C19.1154 74.5159 24.2723 60.3195 19.115 49.5159Z"
        fill="#133866"
      />
      <path
        d="M6.78365 49.6799L35.5875 49.6799C34.373 50.0111 33.2861 50.5931 32.4862 51.426C31.1844 52.7815 30.6677 54.7753 31.5565 57.3518L3.01815 57.3518C1.8694 55.1917 2.18972 53.3524 3.09362 52.0155C4.01558 50.6518 5.5481 49.8057 6.78365 49.6799Z"
        fill="white"
        stroke="black"
        strokeWidth="0.328099"
      />
      <rect x="6.11487" y="52.5156" width="22" height="1" fill="#BAC1C8" />
      <rect x="47.0002" y="42" width="20" height="1" fill="#BAC1C8" />
      <rect x="45.0002" y="45" width="20" height="1" fill="#BAC1C8" />
      <rect x="44.0002" y="48" width="20" height="1" fill="#BAC1C8" />
      <ellipse
        rx="4.80174"
        ry="4.59912"
        transform="matrix(-1 0 0 1 185.802 35.5991)"
        fill="#FEBD27"
      />
      <ellipse
        rx="3.20116"
        ry="3.06608"
        transform="matrix(-1 0 0 1 185.802 35.5993)"
        fill="#FFE298"
      />
      <path d="M113 29H134" stroke="black" strokeWidth="0.5" />
      <path d="M112 33H133" stroke="black" strokeWidth="0.5" />
      <path
        d="M84.0002 54L84.0002 44C84.0002 37.3726 78.6277 32 72.0002 32V32"
        stroke="black"
        strokeWidth="0.5"
      />
      <rect x="168" y="64" width="39" height="9" rx="3" fill="#DE1836" />
      <rect x="172" y="55" width="39" height="9" rx="3" fill="#DE1836" />
      <rect x="174" y="57" width="37" height="5" rx="2.5" fill="#F0F2F4" />
      <rect x="171" y="66" width="37" height="5" rx="2.5" fill="#F0F2F4" />
    </svg>
  ),
  startBulletIcon: (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.38818 1.32778C3.62885 0.805476 4.37115 0.805476 4.61182 1.32778C4.75898 1.64715 5.12703 1.79961 5.45692 1.67784C5.99643 1.47869 6.52131 2.00357 6.32216 2.54308C6.20039 2.87297 6.35285 3.24102 6.67222 3.38818C7.19452 3.62885 7.19452 4.37115 6.67222 4.61182C6.35285 4.75898 6.20039 5.12703 6.32216 5.45692C6.52131 5.99643 5.99643 6.52131 5.45692 6.32216C5.12703 6.20039 4.75898 6.35285 4.61182 6.67222C4.37115 7.19452 3.62885 7.19452 3.38818 6.67222C3.24102 6.35285 2.87297 6.20039 2.54308 6.32216C2.00357 6.52131 1.47869 5.99643 1.67784 5.45692C1.79961 5.12703 1.64715 4.75898 1.32778 4.61182C0.805476 4.37115 0.805476 3.62885 1.32778 3.38818C1.64715 3.24102 1.79961 2.87297 1.67784 2.54308C1.47869 2.00357 2.00357 1.47869 2.54308 1.67784C2.87297 1.79961 3.24102 1.64715 3.38818 1.32778Z"
        fill="#DC343B"
      />
    </svg>
  ),
  pinkSemiCircleIcon: (
    <svg
      width="71"
      height="134"
      viewBox="0 0 71 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.2233 134C65.6933 114.335 70.9999 92.2872 70.9999 69C70.9999 44.1205 64.9428 20.6558 54.2227 0L0.375484 0C16.4434 18.4683 26.172 42.5981 26.172 69.0001C26.172 93.5358 17.7703 116.109 3.68678 134H56.2233Z"
        fill="#FEF6F7"
      />
    </svg>
  ),
  blueSemiCirleIcon: (
    <svg
      width="71"
      height="134"
      viewBox="0 0 71 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1522 134C5.68222 114.335 0.375591 92.2872 0.375591 69C0.375591 44.1205 6.43271 20.6558 17.1527 0L71 0C54.932 18.4683 45.2035 42.5981 45.2035 69.0001C45.2035 93.5358 53.6052 116.109 67.6887 134H15.1522Z"
        fill="#F0F9FF"
      />
    </svg>
  ),
  redTickIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 13L9 17L19 7"
        stroke="#D92D20"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  cross: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke="#737373"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  bgBanner: (
    <svg
      width="289"
      height="84"
      viewBox="0 0 289 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 12.2757 0.122367)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 12.2757 16.9102)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 12.2757 33.6979)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 12.2757 50.4858)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 12.2757 67.2737)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 84.5223 0.122367)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 84.5223 16.9102)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 84.5223 33.6979)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 84.5223 50.4858)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 84.5223 67.2737)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 156.769 0.122367)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 156.769 16.9102)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 156.769 33.6979)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 156.769 50.4858)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 156.769 67.2737)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 229.015 0.122367)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 229.015 16.9102)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 229.015 33.6979)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 229.015 50.4858)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 229.015 67.2737)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 36.3578 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 36.3578 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 36.3578 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 36.3578 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 36.3578 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 108.604 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 108.604 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 108.604 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 108.604 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 108.604 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 180.85 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 180.85 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 180.85 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 180.85 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 180.85 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 253.097 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 253.097 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 253.097 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 253.097 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 253.097 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 60.4398 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 60.4398 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 60.4398 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 60.4398 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 60.4398 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 132.686 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 132.686 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 132.686 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 132.686 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 132.686 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 204.932 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 204.932 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 204.932 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 204.932 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 204.932 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 277.179 0.152885)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 277.179 16.9406)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 277.179 33.7284)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 277.179 50.5163)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
        <rect
          y="0.286283"
          width="14.1868"
          height="14.1868"
          transform="matrix(0.819858 0.572566 -0.819858 0.572566 277.179 67.3041)"
          stroke="#0192FF"
          strokeWidth="0.5"
        />
      </g>
    </svg>
  )
};

export default landingPageIcon;
