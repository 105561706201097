import { Typography } from '@mui/material';

import { svgIcons } from 'assets/svg-icons';
import { ControlWrapper } from 'atoms/control-wrapper';
import { BILLING_ACCOUNTING_FEATURES } from '../constant';
import {
  BillingAccountFeatureItem,
  BillingAccountingFeatureListStyled
} from './billing-accounting-feature-list.styled';

export const BillingAccountingFeatureList = ({
  showCheckIcon = false,
  borderMobileRight = false
}: {
  showCheckIcon?: boolean;
  borderMobileRight?: boolean;
}) => {
  return (
    <BillingAccountingFeatureListStyled className="custom-feature-list-wrapper">
      {BILLING_ACCOUNTING_FEATURES.map((item, index) => {
        const isLast = index === BILLING_ACCOUNTING_FEATURES.length - 1;
        return (
          <BillingAccountFeatureItem
            key={index}
            isLast={isLast}
            borderMobileRight={borderMobileRight}
            className="custom-feature-detail"
          >
            <ControlWrapper shouldShow={showCheckIcon}>
              {svgIcons.checkRed}
            </ControlWrapper>
            <div className="feature-text">
              <Typography variant="body2">{item}</Typography>
              <p className="border-div" />
            </div>
          </BillingAccountFeatureItem>
        );
      })}
    </BillingAccountingFeatureListStyled>
  );
};
