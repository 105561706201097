import styled from '@emotion/styled';
import { Button, useMediaQuery } from '@mui/material';
import theme from '../../../core/theme';
import { svgIcons } from '../../../../assets/svg-icons';
import useLiveDemoInfo from './action';

interface LiveDemoInfoProps {
  title: string;
  description: string;
  ctaText: string;
  source?: string;
}
const LiveDemoInfo = (props: LiveDemoInfoProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { openDemoAccount } = useLiveDemoInfo();
  return (
    <>
      {!isMobile ? (
        <LiveDemoInfoStyle>
          <div className="title">
            {props.description}
            <span>{props.title}</span>
          </div>
          <Button onClick={openDemoAccount} className="cta">
            <span
              className={
                props.source !== 'footer' ? 'cta-title' : 'cta-title-footer'
              }
            >
              {props.ctaText}
            </span>
            <div
              style={{
                position: 'absolute',
                top: theme.spacing(-0.25),
                left: theme.spacing(-0.25)
              }}
            >
              {svgIcons.ctaStripe}
            </div>
            <div
              style={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(-0.25)
              }}
            >
              {svgIcons.ctaStripe2}
            </div>
          </Button>
        </LiveDemoInfoStyle>
      ) : null}
    </>
  );
};

const LiveDemoInfoStyle = styled('div')(({ theme }: any) => ({
  display: 'flex',
  border: `${theme.spacing(0.25)} solid ${theme.palette.commonBgColor.white}`,
  backgroundColor: theme.palette.commonBgColor.infoYellow,
  padding: `${theme.spacing(2)}`,
  justifyContent: 'center',
  borderRadius: `${theme.spacing(2)}`,
  '.title': {
    fontSize: `${theme.spacing(3.5)}`,
    alignSelf: 'center',
    span: {
      fontWeight: 'bold',
      color: theme.palette.commonBgColor.black
    }
  },
  '.cta-title': {
    whiteSpace: 'nowrap',
    margin: `0px ${theme.spacing(8)} 0px ${theme.spacing(7)}`
  },
  '.cta-title-footer': {
    whiteSpace: 'nowrap',
    margin: `0px ${theme.spacing(3)} 0px ${theme.spacing(2)}`
  },
  '.cta': {
    marginLeft: `${theme.spacing(2)}`,
    height: `${theme.spacing(10)}`,
    backgroundColor: `${theme.palette.commonBgColor.darkBlue}`,
    color: `${theme.palette.commonBgColor.white}`,
    fontWeight: '700',
    '&:hover': {
      backgroundColor: `${theme.palette.commonBgColor.darkBlue}`
    }
  }
}));
export default LiveDemoInfo;
