import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CarouselContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '3rem',
  marginBottom: '3rem',
  '& .glide__track': {
    width: '98%',
    [theme.breakpoints.down('lg')]: { maxWidth: '50rem' },
    [theme.breakpoints.between('md', 950)]: { width: '80%' },
    [theme.breakpoints.down('md')]: { maxWidth: '22rem' },
    [theme.breakpoints.down(400)]: { maxWidth: '18rem' }
  },
  [theme.breakpoints.down('sm')]: { minHeight: '10rem' },
  [theme.breakpoints.down('md')]: { minHeight: '10rem' },
  [theme.breakpoints.between('lg', 1250)]: { width: '95%' }
}));

export const BlogCrousalConatiner = styled(Box)(({ theme }) => ({}));
