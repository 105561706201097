import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { Form } from 'react-final-form';

import { svgIcons } from 'assets/svg-icons';
import Button from 'atoms/button';
import Dialog from 'atoms/dialog';
import Loader from 'atoms/loader';
import TextField from 'atoms/text-field';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/appInfo';
import { INDIAN_PHONE_LENGTH, INDIA_COUNTRY_CODE } from 'constants/phone';
import useActions from './actions';
// import BusinessDetails from './business-details'
import LoginDiv from './style';
import { LoginState } from './utils/constants';
import landingPageIcon from 'assets/landing-page-icon';
import ErrorSection from 'components/ErrorSection';

interface LoginProps {
  phoneNumber?: string;
  handleLoginModal?: ({
    open,
    phoneNumber,
    source
  }: {
    open: boolean;
    phoneNumber?: string;
    source?: string;
  }) => void;
  source?: string;
}

const Login = ({ phoneNumber, handleLoginModal, source }: LoginProps) => {
  const {
    open,
    phone,
    otp,
    submitState,
    secondsLeft,
    isOpenBusinessDailog,
    handleClose,
    handleChange,
    handleEdit,
    onSubmit,
    handleSendOtp,
    error
    // handleOpenBusinessDialog,
  } = useActions({ phoneNumber, source });

  const disableContinue =
    !phone?.length || phone?.length < 10 || !otp?.length || otp?.length < 6;
  return (
    <>
      {submitState === LoginState.REQUESTING_OTP ||
        submitState === LoginState.SUBMITTING ? (
        <Loader type="page" />
      ) : null}
      {/*<BusinessDetails open={isOpenBusinessDailog} />*/}

      <Dialog open={open} paperProps={{ sx: { borderRadius: '0.75rem' } }}>
        <LoginDiv>
          <div className="login-header">
            <Button
              onClick={() => {
                handleClose();
                handleLoginModal?.({ open: false });
              }}
            >
              {landingPageIcon.cross}
            </Button>
          </div>
          <div className="khatabook-icon-div">{svgIcons.khatabook_main}</div>
          <Typography
            component="div"
            sx={{
              textAlign: 'center',
              color: 'black'
            }}
            variant="h2"
          >
            Welcome to Khatabook
          </Typography>
          {error ? (
            <>
              <br />
              <ErrorSection message={error} />
            </>
          ) : null}
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="phone"
                  label="Enter phone number"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={handleChange}
                  maxLength={INDIAN_PHONE_LENGTH}
                  disable={
                    submitState === LoginState.OTP_REQUESTED ||
                    submitState === LoginState.REQUESTING_OTP ||
                    submitState === LoginState.SUBMITTING ||
                    submitState === LoginState.SUBMIT_FAILED
                  }
                  startAdornment={
                    <Typography
                      component="div"
                      style={{ marginRight: '0.5rem' }}
                    >
                      {INDIA_COUNTRY_CODE}
                    </Typography>
                  }
                  endAdornment={
                    submitState === LoginState.OTP_REQUESTED ? (
                      <Button
                        disabled={submitState !== LoginState.OTP_REQUESTED}
                        variant="text"
                        color="error"
                        className="edit-btn"
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    ) : null
                  }
                />
                {submitState === LoginState.OTP_REQUESTED ||
                  submitState === LoginState.SUBMITTING ? (
                  <TextField
                    fullWidth
                    name="otp"
                    label="Enter OTP"
                    placeholder="Enter OTP"
                    inputType="number"
                    maxLength={6}
                    value={otp}
                    onChange={handleChange}
                  />
                ) : null}
                {!submitState || submitState == LoginState.REQUESTING_OTP ? (
                  <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    size="large"
                    className="get-otp"
                    disabled={!phone?.length || phone?.length < 10}
                  >
                    Get OTP
                  </Button>
                ) : null}
                {submitState == LoginState.OTP_REQUESTED ? (
                  secondsLeft > 0 ? (
                    <div className="request-otp-text">
                      You can request another OTP in {secondsLeft} seconds
                    </div>
                  ) : (
                    <Button
                      variant="text"
                      color="error"
                      className="resend-otp"
                      onClick={() => handleSendOtp('resend')}
                    >
                      Resend OTP
                    </Button>
                  )
                ) : null}
                {submitState === LoginState.SUBMITTING ? (
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    className="get-otp"
                  >
                    Logging In
                    <Loader
                      sx={{ marginLeft: '1rem' }}
                      size="1rem"
                      color="inherit"
                    />
                  </Button>
                ) : null}
                {submitState === LoginState.OTP_REQUESTED ? (
                  <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    className="continue"
                    sx={{ width: '100%' }}
                    disabled={disableContinue}
                  >
                    Continue
                  </Button>
                ) : null}
              </form>
            )}
          />
          <div className="terms-text-box">
            <Typography component="div" className="terms-text">
              By proceeding, you agree to our&nbsp;
              <a
                target={'_blank'}
                href={TERMS_OF_USE_URL}
                className="terms-text__link"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              &nbsp; and&nbsp;
              <a
                target={'_blank'}
                href={PRIVACY_POLICY_URL}
                className="terms-text__link"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Typography>
          </div>
        </LoginDiv>
      </Dialog>
    </>
  );
};

export default memo(Login);
