import { useEffect, useState } from 'react';

import { Button, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';

import styled from '@emotion/styled';
import { Carousel } from 'features/core/Carousel';
import theme from 'features/core/theme';
import ErrorTracker from 'utils/ErrorTracker';
import BlogCard from './BlogCard';
import { CarouselContainer } from './CustomCarousel';

export default function Blog({ fetch }) {
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
    ? 'lg'
    : '';
  const isSm = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })
    ? 'sm'
    : '';

  const chunks = { sm: 1, md: 1, lg: 2 };
  const [blogs, setBlogs] = useState([]);
  const [nextUrl, setNextUrl] = useState('');
  //2 * chunks[isLg || isSm || 'md'];
  const fetchLimit = 10;

  /**
   * As per product requirent we have to fetch only 10 blogs will fetch only once , so will show ony 10 blogs to user with 5 dots
   *
   */
  useEffect(() => {
    if (fetch && blogs.length === 0)
      fetchBlogs(
        0,
        `https://khatabook.com/api/v1/blog/blog-list/?language_slug=en&limit=${fetchLimit}`
      );
  }, []);

  const fetchBlogs = (page, url?) => {
    if (
      /**
       * As it can be needed in future for logic change.
       */
      //  &&
      // (isLg || (!isLg && blogs.length < 10)) &&
      // blogs.length - page === chunks[isLg || isSm || 'md']
      nextUrl ||
      url
    )
      axios
        .get(url || nextUrl)
        .then(res => {
          setNextUrl(res.data.next);
          setBlogs(url ? res.data.results : [...blogs, ...res.data.results]);
        })
        .catch(err => {
          ErrorTracker.captureException(err);
        });
  };

  const handleViewAll = () => {
    window.location.href = 'https://khatabook.com/blog/';
  };

  return (
    <BlogContainerStyle>
      <Typography style={{ alignSelf: 'center' }} pl={0} variant="h2">
        Our Blogs
      </Typography>
      <CarouselContainer>
        <Carousel
          hasDots={isLg ? true : false}
          hasArrows={blogs.length > 0}
          options={{
            rewind: false,
            type: 'slider',
            startAt: 1,
            gap: !isSm ? 40 : 20,
            perSwipe: '|',
            bound: true,
            focusAt: 0,
            perView: chunks[isLg || isSm || 'md'],
            breakpoints: {
              [theme.breakpoints.values.lg]: { perView: 2 },
              [theme.breakpoints.values.md]: { perView: 1 }
            }
          }}
        >
          {blogs.map((blog, index) => {
            return <BlogCard index={index} key={blog.id} blog={blog} />;
          })}
        </Carousel>
      </CarouselContainer>

      <Button
        variant="contained"
        className="viewAllCta"
        aria-label="VIEW ALL"
        onClick={handleViewAll}
      >
        View All Articles
      </Button>
    </BlogContainerStyle>
  );
}

const BlogContainerStyle = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.viewAllCta': {
    width: 'fit-content',
    alignSelf: 'center',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    borderRadius: '8px',
    marginBottom: '2rem',
    padding: '.75rem 1.5rem',
    ':hover': {
      backgroundColor: `${theme.palette.commonBgColor.darkRed}`,
      color: 'white'
    },
    backgroundColor: `${theme.palette.commonBgColor.brightRed}`
  }
}));
