import styled from '@emotion/styled';
import { Link, Typography } from '@mui/material';
import landingPageIcon from 'assets/landing-page-icon';
const MobileDesktopSummaries = [
  'Create bills faster on desktop',
  'Manage account from multiple devices',
  'Print invoices faster using any printer'
];
const MobileDesktopLandingPageHeader = () => {
  return (
    <MobileDesktopLandingPageHeaderStyle>
      <div className="desktopIcon">{landingPageIcon.desktopPageHeaderIcon}</div>
      <div className="summaryContainer">
        <Typography variant="h4" className="title">
          Open
          <span className="titleSpan">
            <Link
              href="https://khatabook.com/"
              target="_blank"
              underline="none"
              rel="noreferrer"
            >
              {' '}
              khatabook.com
            </Link>
          </span>{' '}
          on your laptop or desktop computer.
        </Typography>
        <Typography className="summary">
          Login using your registered phone number. Your data will automatically
          sync.
        </Typography>
      </div>
      <div className="whyKbDesktopSummary">
        <Typography className="whyKbTitle">
          Why use Khatabook on desktop?
        </Typography>
        <div className="whyKbSummaryContainer">
          {MobileDesktopSummaries.map((item, index) => {
            return (
              <div key={index}>
                <div className="whyKbContent">
                  <div style={{ alignItems: 'center', paddingBottom: '10px' }}>
                    {landingPageIcon.startBulletIcon}
                  </div>
                  <Typography className="whyKbSummaryItem">{item}</Typography>
                </div>
                {index <= 1 && <hr />}
              </div>
            );
          })}
        </div>
        <img
          height={100}
          src="https://khatabook-assets.s3.ap-south-1.amazonaws.com/kb-pwa/why-use-kb-63ca75c5bd.webp"
        ></img>
      </div>
    </MobileDesktopLandingPageHeaderStyle>
  );
};

export default MobileDesktopLandingPageHeader;

const MobileDesktopLandingPageHeaderStyle = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  '.desktopIcon': {
    alignSelf: 'end',
    marginBottom: '-12px',
    marginRight: '15px'
  },
  '.whyKbDesktopSummary': {
    borderRadius: '8px',
    backgroundColor: '#FAE0E2',
    display: 'flex',
    flexDirection: 'column'
  },
  '.whyKbTitle': {
    fontSize: '14px',
    fontWeight: '600',
    marginTop: '1.5rem'
  },
  '.whyKbContent': {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'start'
  },
  '.whyKbSummaryItem': {
    color: '#4E5E76',
    marginLeft: '10px',
    marginTop: '8px',
    fontSize: '14px'
  },
  '.whyKbSummaryContainer': {
    padding: '.5rem 1rem 0rem 1rem'
  },
  hr: {
    backgroundPosition: 'bottom',
    backgroundRepeat: 'repeat-x',
    margin: '.25rem 0px',
    border: '1px dashed #4E5E76',
    height: '1px',
    order: 2,
    alignSelf: 'stretch'
  },
  '.summaryContainer': {
    display: 'flex',
    padding: '2rem',
    borderRadius: '12px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundImage: `linear-gradient(${theme.palette.commonBgColor.white},${theme.palette.commonBgColor.lightPink} )`
  },
  '.title': {
    fontSize: '12px',
    fontWeight: '600'
  },
  '.titleSpan': {
    color: 'blue',
    fontWeight: '600'
  },
  '.summary': {
    fontSize: '12px'
  }
}));
