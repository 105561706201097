import { useState } from 'react';
import useLandingEvents from '../analytics';
export const useFooterActions = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [source, setSource] = useState<string>('');

  const { homeStartTrialClick } = useLandingEvents();

  const handleLoginModal = ({
    open,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    phoneNumber
  }: {
    open: boolean;
    phoneNumber?: string;
  }) => {
    setLoginModal(open);
  };

  const updateSource = source => {
    setSource(source);
  };

  return {
    phoneNumber,
    setPhoneNumber,
    loginModal,
    source,
    handleLoginModal,
    updateSource
  };
};
